export default [
    'Early Revenue', 
    'Growth', 
    'Idea', 
    'Pilot', 
    'Pre-Launch', 
    'Pre-Pilot', 
    'Pre-Product', 
    'Pre-Revenue', 
    'Prototype', 
    'Scale'
]