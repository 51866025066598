import React, {useState} from 'react';
import { Table } from 'antd';

const Deal = props => {

    const { data, columns, pageSize, loading } = props;
    const [filtered_state, set_filtered_state] = useState("");
    
    return (
        <React.Fragment>
            {filtered_state}
            <Table 
                columns={columns} 
                dataSource={data} 
                pagination={{ 
                    defaultPageSize: pageSize, 
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50']  
                }}
                onChange={(pagination, filters, sorter, extra) => 
                    {
                        if(filters.interest.length===0)
                            set_filtered_state("");
                        else
                            set_filtered_state("Filtered: " + extra.currentDataSource.length);
                    }
                }
                loading={loading ? true : false}
            ></Table>
        </React.Fragment>
    )
}

export default Deal;